<template src="./DigitalPassTemplateDeleteModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal.vue";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    cardId: {
      type: Number,
      required: true,
    },
    passTypesValue: {
      type: Number,
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
    };
  },
  computed: {
    modalVisible() {
      return this.$store.state.digitalPass.deleteModalVisible;
    },
  },
  methods: {
    cancelModal() {
      this.$store.dispatch("deleteCloseModal");
    },
    deletePassTemplate() {
      let payload = {
        id: this.clubId,
        templateId: this.cardId,
        TemplateTypeId: this.passTypesValue?.id,
        limit: -1
      };

      this.$store.dispatch("deletePassTemplates", payload);
      this.$store.commit("DELETE_MODAL_VISIBLE", false);
    },
    modalHandler(data) {
      this.$store.commit("DELETE_MODAL_VISIBLE", false);
    },
  },
};
</script>

<style></style>