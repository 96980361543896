<template src="./AllVoucher.html"></template>
<script>
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImage,
  CardActions,
} from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import AppPopUp from "../../common/AppPopUp/AppPopUp";
import DigitalPassTemplateDeleteModal from "../digitalPass/digitalPassTemplateDeleteModal/DigitalPassTemplateDeleteModal.vue";
import voucherDeleteOrReplicateModalVue from "./voucherDeleteOrReplicateModal/voucherDeleteOrReplicateModal.vue";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../common/AppLoader/AppLoader";
export default {
  name: "AllVoucher",
  components: {
    kbutton: Button,
    "k-input": Input,
    dropdownlist: DropDownList,
    card: Card,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardImage: CardImage,
    cardActions: CardActions,
    Popup: Popup,
    AppPopUp,
    Breadcrumb,
    DigitalPassTemplateDeleteModal: DigitalPassTemplateDeleteModal,
    voucherDeleteOrReplicateModalVue: voucherDeleteOrReplicateModalVue,
    AppSnackBar,
    AppLoader,
  },
  data() {
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      loader: true,
      ifUserIsReportViewer: false,
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      debounce: null,
      clubId: this.$route.params.id,
      searchQuery: "",
      cardIdDlt: "",
      isSearching: false,
      templates: [
        { text: "All", id: 0 },
        { text: "Loyalty Card", id: 1 },
        { text: "Membership Card", id: 2 },
        { text: "Event Ticket", id: 3 },
      ],
      passTypesValue: { text: "All", id: 0 },
      show: true,
      voucherDropdownitems: [
        {
          text: "Download Report",
          dropId: 2,
        },
        // {
        //   text: "Delete Voucher",
        //   dropId: 3,
        // },
      ],
      cardDropDown: [
        {
          text: "Delete",
          dropId: 1,
        },
      ],
    };
  },
  created() {
    // userValidation();
    const clubInfo = JSON.parse(window.localStorage.getItem("clubInfo"));
    if (clubInfo?.roleId === 2) {
      this.ifUserIsReportViewer = true;
    } else {
      this.ifUserIsReportViewer = false;
    }
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }

    this.getAllVouchers();
    setTimeout(() => {
      this.loader = false;
    }, 3000);
  },
  unmounted() {
    this.$store.dispatch("clearVouchers");
  },
  computed: {
    clubName() {
      return this.$store.state.club.club.name;
    },
    deleteModalVisible() {
      return this.$store.state.digitalPass.deleteModalVisible;
    },
    voucherTemplate() {
      return this.$store.state.voucherLists.voucherLists;
    },
    voucherItems() {
      if (this.voucherTemplate.items) {
        this.loader = false;
      }
      return this.voucherTemplate.items;
    },
    isshowVoucherMessage() {
      return this.$store.state.voucherLists.showMsgVouchers;
    },
    showVoucherMsg() {
      return this.$store.state.voucherLists.voucherStatus;
    },
    isShowReportMsg() {
      return this.$store.state.voucherReports.showMsg;
    },
    reportMsg() {
      return this.$store.state.voucherReports.status;
    },
    isLoading() {
      return this.$store.state.voucherLists.loadingVouchers;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    getAllVouchers() {
      let payload = {
        clubId: this.clubId,
        limit: -1,
      };
      this.$store.dispatch("getAllVouchers", payload);
    },
    navigateToCreatePage() {
      this.$router.push({
        name: "CreateVoucherHomepage",
        query: { club_name: this.clubName },
      });
    },
    navigateToReportPage() {
      this.$router.push({
        name: "VoucherReport",
        query: { clubName: this.clubName },
      });
    },

    debounceSearch(event) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.onSearch(event);
      }, 600);
    },

    onSearch(e) {
      this.searchQuery = e.value;
      this.isSearching = true;
      if (!this.searchQuery) {
        setTimeout(() => {
          this.isSearching = false;
        }, 1000);
      }
      let payload = {
        clubId: this.clubId,
        searchText: this.searchQuery,
        limit: -1,
      };
      this.$store.dispatch("getAllVouchers", payload);
    },
    navigateToVoucherReportPage(card) {
      this.$router.push({
        name: "VoucherReport",
        query: {
          voucher_campaign_id: card.voucherCampaignId,
          club_name: this.clubName,
          type: +this.$route.query.type || 3,
          clubName: card?.name,
        },
        params: {
          voucherCampaignId: card.voucherCampaignId,
        },
      });
    },
    navigateToManageRecipients(card) {
      this.$router.push({
        name: "VoucherManageRecipients",
        query: {
          voucherCampaignId: card.voucherCampaignId,
          club_name: this.clubName,
          type: +this.$route.query.type || 3,
        },
        params: {
          voucherCampaignId: card.voucherCampaignId,
        },
      });
    },
    navigateToEditVoucher(card) {
      ;
      this.$router.push({
        name: "EditVoucherCampaign",
        query: {
          voucher_id: card.voucherCampaignId,
          voucher_type: card.voucherType,
        },
      });
    },
    onClick() {
      this.show = !this.show;
    },
    droptemplate(cardId) {
      this.$store.commit("DELETE_MODAL_VISIBLE", true);
      this.cardIdDlt = cardId;
    },
    cardNameFormat(name) {
      return name.length <= 50 ? name : name.substring(0, 50) + "..";
    },
    reportDownLoadHandler(voucher) {
      let payload = {
        clubId: +this.clubId,
        voucherCampaignId: voucher?.voucherCampaignId,
        name: voucher?.name,
      };
      this.$store.dispatch("voucherReportExport", payload);
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
    getDropId: function (e, voucher) {
      ;
      if (e == 1) {
        this.droptemplate(voucher?.voucherCampaignId);
      }
      if (e == 2) {
        this.reportDownLoadHandler(voucher);
      }
      if (e == 3) {
        if (!voucher?.isVoucherEmailSent) {
          this.droptemplate(voucher?.voucherCampaignId);
        } else {
          this.showNotification(
            "You can't delete this voucher program because one or more vouchers are in use by your audiences.",
            "warning"
          );
        }
      }
    },
  },
};
</script>
<style scoped></style>
