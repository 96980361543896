<template src="./voucherDeleteOrReplicateModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../common/AppModal/AppModal.vue";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    cardId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
    };
  },
  computed: {
    modalVisible() {
      return this.$store.state.digitalPass.deleteModalVisible;
    },
  },
  methods: {
    cancelModal() {
      this.$store.dispatch("deleteCloseModal");
    },
    deleteVoucher() {
      let payload = {
        clubId: this.clubId,
        voucherCampaignId: this.cardId,
      };
      this.$store.dispatch("deleteVoucher", payload);
      this.$store.commit("DELETE_MODAL_VISIBLE", false);
    },
    modalHandler(data) {
      this.$store.commit("DELETE_MODAL_VISIBLE", false);
    },
  },
};
</script>

<style></style>
