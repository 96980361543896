<modal title="Delete Voucher" :handler="modalVisible" :cssClass="'c-modal-small-2 content-px-0'" :footer="false"
    @modalHandler="modalHandler">
    <template v-slot:content>
        <div class="px-16">
            <p class="font-16 m-0 py-5 pt-4 text-center">
                Are you sure you want to delete this voucher? </p>
        </div>
        <dialog-actions-bar>
            <div class="d-flex justify-content-between py-1 px-2">
                <div class="me-1 w-50-p">
                    <kbutton @click="cancelModal" :class="'w-100'">Cancel</kbutton>
                </div>
                <div class="ms-1 w-50-p">
                    <kbutton :theme-color="'primary'" @click="deleteVoucher" :class="'w-100'">Remove</kbutton>
                </div>
            </div>
        </dialog-actions-bar>
    </template>
</modal>